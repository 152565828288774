<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 ps-0">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">非凸科技与中泰证券达成合作</p>
              <div class="fst-italic mb-2">2020年7月29日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >重磅</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >中泰证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <figure class="mb-4">
                  <img
                    class="img-fluid rounded w-100"
                    src="../../assets/img/news/01/image01.jpg"
                    alt="非凸科技与众泰证券达成合作"
                  />
                </figure>
                <section class="mb-5">
                  <p class="mb-4">
                    近日，上海非凸智能科技有限公司（简称“非凸科技”）与中泰证券股份有限公司（简称“中泰证券”）达成合作，双方将在智能算法服务、市场品牌推广等方面通力协作，共同为构建数智交易生态圈而努力。
                  </p>
                  <p class="mb-4">
                    双方还就合作的领域、方式、途径等方面进行了深入交流。双方一致认为，在新的机遇和挑战面前，双方将协同共进，共商共赢，充分运用各自资源和技术优势，在服务客户、完善产品体系等方面探索合作新模式，拓宽合作新渠道，探索推出新的金融产品。
                  </p>
                  <p class="mb-4">
                    此次双方合作，将为智能算法交易领域的技术创新提供了更多的探索方向。
                  </p>
                  <p class="mb-4">
                    未来，双方将加强更多的创新业务合作，向着更加紧密、更加适应行业发展趋势的方向，共赢共生。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News01",
};
</script>

<style></style>
